import { FC, useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import classNames from "classnames";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

import { useBreakpoint } from "hooks/useBreakpoint";
import { useUser } from "context/UserContext";
import { getInvoice, getOrder, getUtd } from "services/orderApi";
import { Spinner2 } from "components/Spinner2";
import { PageHeader } from "components/PageHeader";
import { dateFormat, priceFormat } from "utils/format";
import { openAddress } from "utils/openAddress";
import { ReactComponent as DocumentIcon } from "assets/img/svg/document.svg";
import { TImage } from "components/ProductModal";
import { MainBlock } from "layout/MainBlock";
import { Body } from "layout/Body";
import { RightSidebar } from "layout/RightSidebar";
import { Cut } from "components/Cut";
import { BreadCrumbs } from "layout/BreadCrumbs";

import { ReactComponent as PointIcon } from "./point.svg";

import styles from "./orderOne.module.scss";

export type TStatus = {
  id: string;
  color: string;
  name: string;
  nameForCustomer: string;
};

export type TAdmin = {
  id: string;
  name: string;
  phone: string;
  email: string;
};

export type TDelivery = {
  id: number;
  name: string;
};

export type TNomenclature = {
  id: string;
  article: string;
  brand: string;
  name: string;
  images: TImage[];
  image?: string;
};

export type TProduct = {
  id: string;
  index: number;
  nomenclature: TNomenclature;
  price: string;
  count: number;
  summ: number;
};

export type TDeliverySettings = {
  address: string;
  city?: string;
  transportCompany?: string;
  senderIsPayer?: boolean;
  comment?: string;
  contact?: string;
};

export type TPayment = {
  id: string;
  name?: string;
};

export type TOrder = {
  id: string;
  number: number;
  date: string;
  status: TStatus;
  payment: TPayment;
  admin: TAdmin;
  delivery: TDelivery;
  deliverySettings: TDeliverySettings;
  commentClient: string;
  carriedOut: boolean;
  products: TProduct[];
  summ: string;
  count: number;
};

export const OrderOnePage: FC = () => {
  const totalSummBlock = useRef<HTMLDivElement | null>(null);
  const orderTopWrapper = useRef<HTMLDivElement | null>(null);
  const { breakpoint, isDesktop, isMobile } = useBreakpoint();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState<TOrder | null>(null);
  const [shownOrderInfo, setShownOrderInfo] = useState(false);

  const getAndSetOrder = () => {
    if (!id) return;
    setLoading(true);
    getOrder(id)
      .then((res) => {
        setOrder(res);
      })
      .catch((e) => toast.error(e.response.data.message))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getAndSetOrder();
  }, []);

  const invoiceHandler = () => {
    if (!order?.id) return;
    setLoading(true);
    getInvoice({
      id: order?.id,
      withSign: true
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Счет № ${order?.number}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const utdHandler = () => {
    if (!order?.id) return;
    setLoading(true);
    getUtd({
      id: order?.id
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `УПД № ${order?.number}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const showButton = () => {
      const windowHeight = window.innerHeight;
      const fullHeight = document.body.clientHeight;
      const scrolled = window.scrollY;
      if (totalSummBlock.current && orderTopWrapper.current) {
        if (scrolled >= fullHeight - windowHeight) {
          totalSummBlock.current.style.bottom = "44px";
          orderTopWrapper.current.style.bottom = "89px";
        } else {
          totalSummBlock.current.removeAttribute("style");
          orderTopWrapper.current.removeAttribute("style");
        }
      }
    };
    window.addEventListener("scroll", showButton);

    return () => window.removeEventListener("scroll", showButton);
  }, []);

  const orderInfoBlock = () => (
    <>
      <div className={styles.orderTop}>
        <div className={styles.info}>
          <div className={styles.title}>Информация о заказе</div>
          <div className={styles.infoItem}>
            <div>№ Заказа</div>
            <div />
            <div>{order?.number}</div>
          </div>
          <div className={styles.infoItem}>
            <div>Дата заказа</div>
            <div />
            <div>{dateFormat(order?.date)}</div>
          </div>
          <div className={styles.infoItem}>
            <div>Статус</div>
            <div />
            <div
              className={styles.status}
              style={{ backgroundColor: order?.status.color }}
            >
              {order?.status?.nameForCustomer}
            </div>
          </div>
          <div className={styles.infoItem}>
            <div>Способ оплаты</div>
            <div />
            <div>{order?.payment.name}</div>
          </div>
          <div className={styles.infoItem}>
            <div>Менеджер</div>
            <div />
            <div>{order?.admin?.name}</div>
          </div>
        </div>
        <div>
          <div className={styles.title}>
            Способ получения {!isDesktop && <span>{order?.delivery.name}</span>}
          </div>
          {isDesktop && (
            <div className={styles.deliveryItem}>
              <div>{order?.delivery.name}</div>
            </div>
          )}

          <div className={styles.deliveryItem}>
            <div>Адрес:</div>
            {order?.delivery?.id === 1 ? (
              <div>Москва, ул. Нижние Поля, 31c1, склад "ЛИДЕР ТРАК"</div>
            ) : (
              <div>{order?.deliverySettings?.address}</div>
            )}
          </div>
          <div className={styles.deliveryItem}>
            <div>Время:</div>
            <div>пн-пт с 9:00 до 18:00, с6 9:00 до 13:00, вс - выходной</div>
          </div>
          <div className={styles.deliveryItem}>
            <div>Телефон:</div>
            <div>+7(999) 916-50-00</div>
          </div>
          <div className={styles.deliveryItem}>
            {order && (
              <a
                href={openAddress(
                  order?.delivery?.id === 1
                    ? "г. Москва ул. Нижние Поля, 31с1, склад ЛТ «ЛИДЕР ТРАК»"
                    : order?.deliverySettings?.address
                )}
                target="_blank"
                rel="noreferrer"
                className={classNames(
                  styles.deliveryButton,
                  styles[breakpoint]
                )}
              >
                <PointIcon />
                Посмотреть на карте
              </a>
            )}
          </div>
        </div>
        <div>
          <div>
            <div className={styles.title}>Комментарий к заказу</div>
            <div className={styles.comment}>{order?.commentClient}</div>
          </div>

          <div className={styles.documents}>
            <div className={styles.title}>Печать документа</div>
            <div className={styles.documentButtons}>
              <button
                className={styles.documentButton}
                onClick={invoiceHandler}
              >
                <DocumentIcon />
                Счет на оплату
              </button>
              {order?.carriedOut &&
                <button
                  className={styles.documentButton}
                  onClick={utdHandler}
                >
                  <DocumentIcon />
                  Упд
                </button>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const Product = ({ product }: { product: TProduct }) => {
    if (isDesktop) {
      return (
        <div className={styles.productItem} key={product.id}>
          <div>{product.index}</div>
          <div>{product.nomenclature?.brand}</div>
          <div>{product.nomenclature?.article}</div>
          <div>{product.nomenclature?.name}</div>
          <div></div>
          <div>{priceFormat(product.price)} р.</div>
          <div>{product.count}</div>
          <div>{priceFormat(product.summ)} р.</div>
        </div>
      );
    } else {
      return (
        <div className={styles.productItem} key={product.id}>
          <div>
            <div className={styles.productInfo}>
              <span className={styles.index}>№</span>
              {product.index}{" "}
              <span className={styles.brand}>
                {product.nomenclature?.brand}
              </span>{" "}
              <span className={styles.article}>
                {product.nomenclature?.article}
              </span>
            </div>
            <div className={styles.name}>
              <Cut>{product.nomenclature?.name}</Cut>
            </div>
          </div>
          <div>
            <div>{product.count} шт.</div>
            <div>1 день</div>
          </div>
          <div>
            <div>{priceFormat(product.price)} р.</div>
            <div className={styles.summ}>{priceFormat(product.summ)} р.</div>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <BreadCrumbs
        data={[
          { title: "Заказы", link: "/orders" },
          {
            title: `Заказ №${order?.number || ""} от ${dateFormat(
              order?.date
            )}`,
          },
        ]}
      />
      <Helmet>
        <title>
          Заказ №{`${order?.number || ""} от ${dateFormat(order?.date)}`}
        </title>
      </Helmet>
      <div className={classNames(styles.order, styles[breakpoint])}>
        {loading && <Spinner2 overlay />}

        <Body>
          <PageHeader title={`Заказ № ${order?.number}`} />
          <MainBlock className={styles.mainBlock}>
            {isDesktop ? (
              <>{orderInfoBlock()}</>
            ) : (
              <>
                <div
                  className={classNames(styles.orderTopWrapper, {
                    [styles.show]: shownOrderInfo,
                  })}
                  ref={orderTopWrapper}
                >
                  <div
                    className={styles.treangle}
                    onClick={() => setShownOrderInfo((prev) => !prev)}
                  />
                  {orderInfoBlock()}
                </div>
                <div className={styles.totalSummBlock} ref={totalSummBlock}>
                  <div>Сумма:</div>
                  <div className={styles.totalSumm}>
                    {priceFormat(order?.summ)} р.
                  </div>
                </div>
              </>
            )}

            <div className={styles.products}>
              {isDesktop && (
                <div className={styles.productsTitle}>
                  <div className={styles.title}>Товарные позиции</div>
                </div>
              )}
              {isDesktop && (
                <div>
                  <div className={styles.productsHead}>
                    <div>№</div>
                    <div>Бренд</div>
                    <div>Артикул</div>
                    <div>Наименование</div>
                    <div>Срок поставки</div>
                    <div>Цена</div>
                    <div>Кол-во</div>
                    <div>Сумма</div>
                  </div>
                </div>
              )}
              <div className={styles.productsList}>
                {order?.products.map((product: TProduct) => (
                  <Product product={product} key={product.id} />
                ))}
              </div>
              {isDesktop && (
                <div className={styles.productsFoot}>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div>Итого:</div>
                  <div>{order?.count}</div>
                  <div>{priceFormat(order?.summ)} р.</div>
                </div>
              )}
            </div>
          </MainBlock>
        </Body>
        {!isMobile && <RightSidebar />}
      </div>
    </>
  );
};
